@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.font-manrope {
  font-family: 'Manrope', sans-serif;
}

.text-my-blue {
  color: #0041f5;
}

.text-my-gray {
  color: #292b2e;
}

.text-my-light-gray {
  color: #292b2e;
}

#intro {
  background: url('../images/bg.jpg?quality=70&as=webp') no-repeat center/cover
    fixed;
}

/* block paddings */

#support {
  padding-block: 40px 30px;
}

#services {
  padding-block: 40px 30px;
}

#stack {
  padding-block: 30px 40px;
}

#about {
  padding-block: 40px 30px;
}

#director {
  padding-block: 30px 0;
}

#steps {
  padding-block: 40px;
}

#enterprises {
  padding-block: 40px 30px;
}

#consult {
  padding-block: 30px 40px;
}

#contacts {
  padding-block: 30px;
}

#footer {
  padding-block: 30px 40px;
}

@media (min-width: 1024px) {
  #support {
    padding-block: 100px 80px;
  }

  #services {
    padding-block: 70px 90px;
  }

  #stack {
    padding-block: 70px 80px;
  }

  #about {
    padding-block: 90px 70px;
  }

  #director {
    padding-block: 0;
  }

  #steps {
    padding-block: 70px 80px;
  }

  #enterprises {
    padding-block: 90px 60px;
  }

  #consult {
    padding-block: 60px 50px;
  }

  #contacts {
    padding-block: 30px 50px;
  }

  #footer {
    padding-block: 30px 50px;
  }
}

/* custom styles */

.support-cards > *:nth-child(1) {
  background: url('../images/support-bgs/1.jpg?quality=80&as=webp') center/cover;
}

.support-cards > *:nth-child(2) {
  background: url('../images/support-bgs/2.jpg?quality=80&as=webp') center/cover;
}

.support-cards > *:nth-child(3) {
  background: url('../images/support-bgs/3.jpg?quality=80&as=webp') center/cover;
}

.support-cards > *:nth-child(4) {
  background: url('../images/support-bgs/4.jpg?quality=80&as=webp') center/cover;
}

.support-cards > *:nth-child(5) {
  background: url('../images/support-bgs/5.jpg?quality=80&as=webp') center/cover;
}

.support-cards > *:nth-child(6) {
  background: url('../images/support-bgs/6.jpg?quality=80&as=webp') center/cover;
}

.support-cards > *:nth-child(7) {
  background: url('../images/support-bgs/7.jpg?quality=80&as=webp') center/cover;
}

.support-cards > *:nth-child(8) {
  background: url('../images/support-bgs/8.jpg?quality=80&as=webp') center/cover;
}

@media (min-width: 768px) {
  .support-cards > *:nth-child(1) {
    background: url('../images/support-bgs/desktop/1.jpg?quality=80&as=webp')
      center/cover;
  }

  .support-cards > *:nth-child(2) {
    background: url('../images/support-bgs/desktop/2.jpg?quality=80&as=webp')
      center/cover;
  }

  .support-cards > *:nth-child(3) {
    background: url('../images/support-bgs/desktop/3.jpg?quality=80&as=webp')
      center/cover;
  }

  .support-cards > *:nth-child(4) {
    background: url('../images/support-bgs/desktop/4.jpg?quality=80&as=webp')
      center/cover;
  }

  .support-cards > *:nth-child(5) {
    background: url('../images/support-bgs/desktop/5.jpg?quality=80&as=webp')
      center/cover;
  }

  .support-cards > *:nth-child(6) {
    background: url('../images/support-bgs/desktop/6.jpg?quality=80&as=webp')
      center/cover;
  }

  .support-cards > *:nth-child(7) {
    background: url('../images/support-bgs/desktop/7.jpg?quality=80&as=webp')
      center/cover;
  }

  .support-cards > *:nth-child(8) {
    background: url('../images/support-bgs/desktop/8.jpg?quality=80&as=webp')
      center/cover;
  }
}

.service * {
  transition: 300ms all ease-out;
}

.service--active .service-hidden {
  max-height: 500px;
  margin: 0;
}

.service--active .service-button img {
  rotate: 180deg;
}

.modals {
  pointer-events: none;
  opacity: 0;
}

.modals.is-active {
  pointer-events: auto;
  opacity: 1;
}

@media (min-width: 1024px) {
  .service-hidden {
    max-height: unset;
  }
}
