@import "https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1920px;
  }
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-\[-20px\] {
  top: -20px;
}

.z-50 {
  z-index: 50;
}

.z-\[-1\] {
  z-index: -1;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-\[-20px\] {
  margin-top: -20px;
  margin-bottom: -20px;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-\[20px\] {
  margin-bottom: 20px;
}

.mb-\[30px\] {
  margin-bottom: 30px;
}

.mb-\[40px\] {
  margin-bottom: 40px;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mr-\[5px\] {
  margin-right: 5px;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.aspect-\[2\/3\] {
  aspect-ratio: 2 / 3;
}

.aspect-\[33\/26\] {
  aspect-ratio: 33 / 26;
}

.aspect-\[35\/20\] {
  aspect-ratio: 35 / 20;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[224px\] {
  height: 224px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[5px\] {
  height: 5px;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.max-h-0 {
  max-height: 0;
}

.min-h-screen {
  min-height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-\[160px\] {
  width: 160px;
}

.w-\[170px\] {
  width: 170px;
}

.w-\[24px\] {
  width: 24px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[5px\] {
  width: 5px;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[calc\(50\%-5px\)\] {
  width: calc(50% - 5px);
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.max-w-\[730px\] {
  max-width: 730px;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.list-disc {
  list-style-type: disc;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[15px\] {
  gap: 15px;
}

.gap-\[20px\] {
  gap: 20px;
}

.gap-\[30px\] {
  gap: 30px;
}

.gap-\[4px\] {
  gap: 4px;
}

.gap-\[50px\] {
  gap: 50px;
}

.gap-\[5px\] {
  gap: 5px;
}

.gap-y-\[50px\] {
  row-gap: 50px;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[16px\] {
  border-radius: 16px;
}

.rounded-\[6px\] {
  border-radius: 6px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.border-b {
  border-bottom-width: 1px;
}

.border-\[\#D6DCE1\] {
  --tw-border-opacity: 1;
  border-color: rgb(214 220 225 / var(--tw-border-opacity));
}

.bg-\[\#0000003f\] {
  background-color: #0000003f;
}

.bg-\[\#2360F5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(35 96 245 / var(--tw-bg-opacity));
}

.bg-\[\#43CE37\] {
  --tw-bg-opacity: 1;
  background-color: rgb(67 206 55 / var(--tw-bg-opacity));
}

.bg-\[\#545558\] {
  --tw-bg-opacity: 1;
  background-color: rgb(84 85 88 / var(--tw-bg-opacity));
}

.bg-\[\#EAEAEF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 234 239 / var(--tw-bg-opacity));
}

.bg-\[\#F7F7F9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 249 / var(--tw-bg-opacity));
}

.bg-\[\#FAFAFC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 252 / var(--tw-bg-opacity));
}

.bg-\[\#FF7539\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 117 57 / var(--tw-bg-opacity));
}

.bg-\[\#fefefe59\] {
  background-color: #fefefe59;
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-top {
  object-position: top;
}

.p-0 {
  padding: 0;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[20px\] {
  padding: 20px;
}

.p-\[4\%\] {
  padding: 4%;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-\[15px\] {
  padding-left: 15px;
  padding-right: 15px;
}

.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

.px-\[30px\] {
  padding-left: 30px;
  padding-right: 30px;
}

.px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}

.px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[20px\] {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-\[30px\] {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-\[40px\] {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-\[46px\] {
  padding-top: 46px;
  padding-bottom: 46px;
}

.pt-\[60px\] {
  padding-top: 60px;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[26px\] {
  font-size: 26px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-\[130\%\] {
  line-height: 130%;
}

.leading-\[140\%\] {
  line-height: 140%;
}

.leading-\[160\%\] {
  line-height: 160%;
}

.leading-\[16px\] {
  line-height: 16px;
}

.leading-\[30px\] {
  line-height: 30px;
}

.leading-\[auto\] {
  line-height: auto;
}

.text-\[\#0041F5\] {
  --tw-text-opacity: 1;
  color: rgb(0 65 245 / var(--tw-text-opacity));
}

.text-\[\#2360F5\] {
  --tw-text-opacity: 1;
  color: rgb(35 96 245 / var(--tw-text-opacity));
}

.text-\[\#DEE7FB\] {
  --tw-text-opacity: 1;
  color: rgb(222 231 251 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.shadow-\[0px_15px_20px_rgba\(81\,81\,81\,0\.04\)\] {
  --tw-shadow: 0px 15px 20px #5151510a;
  --tw-shadow-colored: 0px 15px 20px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-\[10px\] {
  --tw-backdrop-blur: blur(10px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.font-manrope {
  font-family: Manrope, sans-serif;
}

.text-my-blue {
  color: #0041f5;
}

.text-my-gray, .text-my-light-gray {
  color: #292b2e;
}

#intro {
  background: url("bg.202cdc3e.webp") center / cover no-repeat fixed;
}

#support, #services {
  padding-block: 40px 30px;
}

#stack {
  padding-block: 30px 40px;
}

#about {
  padding-block: 40px 30px;
}

#director {
  padding-block: 30px 0;
}

#steps {
  padding-block: 40px;
}

#enterprises {
  padding-block: 40px 30px;
}

#consult {
  padding-block: 30px 40px;
}

#contacts {
  padding-block: 30px;
}

#footer {
  padding-block: 30px 40px;
}

@media (min-width: 1024px) {
  #support {
    padding-block: 100px 80px;
  }

  #services {
    padding-block: 70px 90px;
  }

  #stack {
    padding-block: 70px 80px;
  }

  #about {
    padding-block: 90px 70px;
  }

  #director {
    padding-block: 0;
  }

  #steps {
    padding-block: 70px 80px;
  }

  #enterprises {
    padding-block: 90px 60px;
  }

  #consult {
    padding-block: 60px 50px;
  }

  #contacts, #footer {
    padding-block: 30px 50px;
  }
}

.support-cards > :nth-child(1) {
  background: url("1.72f0da60.webp") center / cover;
}

.support-cards > :nth-child(2) {
  background: url("2.3b4cbe6d.webp") center / cover;
}

.support-cards > :nth-child(3) {
  background: url("3.a0ab5cd7.webp") center / cover;
}

.support-cards > :nth-child(4) {
  background: url("4.b4211e94.webp") center / cover;
}

.support-cards > :nth-child(5) {
  background: url("5.c9ada8b9.webp") center / cover;
}

.support-cards > :nth-child(6) {
  background: url("6.db748fc5.webp") center / cover;
}

.support-cards > :nth-child(7) {
  background: url("7.d000a55a.webp") center / cover;
}

.support-cards > :nth-child(8) {
  background: url("8.c819c2b5.webp") center / cover;
}

@media (min-width: 768px) {
  .support-cards > :nth-child(1) {
    background: url("1.1618aea4.webp") center / cover;
  }

  .support-cards > :nth-child(2) {
    background: url("2.cc0c9bb4.webp") center / cover;
  }

  .support-cards > :nth-child(3) {
    background: url("3.0f93e0e9.webp") center / cover;
  }

  .support-cards > :nth-child(4) {
    background: url("4.287fd53e.webp") center / cover;
  }

  .support-cards > :nth-child(5) {
    background: url("5.244e9d3b.webp") center / cover;
  }

  .support-cards > :nth-child(6) {
    background: url("6.99b71a2b.webp") center / cover;
  }

  .support-cards > :nth-child(7) {
    background: url("7.afa757f6.webp") center / cover;
  }

  .support-cards > :nth-child(8) {
    background: url("8.132cdd74.webp") center / cover;
  }
}

.service * {
  transition: all .3s ease-out;
}

.service--active .service-hidden {
  max-height: 500px;
  margin: 0;
}

.service--active .service-button img {
  rotate: 180deg;
}

.modals {
  pointer-events: none;
  opacity: 0;
}

.modals.is-active {
  pointer-events: auto;
  opacity: 1;
}

@media (min-width: 1024px) {
  .service-hidden {
    max-height: unset;
  }
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-\[\#FA6424\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 100 36 / var(--tw-bg-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:underline-offset-2:hover {
  text-underline-offset: 2px;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 640px) {
  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-\[40\%\] {
    width: 40%;
  }

  .sm\:w-\[60\%\] {
    width: 60%;
  }

  .sm\:w-\[calc\(\(100\%-20px\)\/3\)\] {
    width: calc(33.3333% - 6.66667px);
  }

  .sm\:w-\[calc\(25\%-8px\)\] {
    width: calc(25% - 8px);
  }

  .sm\:w-\[calc\(50\%-10px\)\] {
    width: calc(50% - 10px);
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:gap-\[20px\] {
    gap: 20px;
  }
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-\[40px\] {
    height: 40px;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-\[160px\] {
    width: 160px;
  }

  .md\:w-\[295px\] {
    width: 295px;
  }

  .md\:w-\[60\%\] {
    width: 60%;
  }

  .md\:w-\[calc\(40\%-30px\)\] {
    width: calc(40% - 30px);
  }

  .md\:w-\[calc\(50\%-15px\)\] {
    width: calc(50% - 15px);
  }

  .md\:max-w-\[40\%\] {
    max-width: 40%;
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-\[30px\] {
    gap: 30px;
  }

  .md\:text-inherit {
    color: inherit;
  }

  .md\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

@media (min-width: 1024px) {
  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-3 {
    order: 3;
  }

  .lg\:mb-\[100px\] {
    margin-bottom: 100px;
  }

  .lg\:mb-\[10px\] {
    margin-bottom: 10px;
  }

  .lg\:mb-\[110px\] {
    margin-bottom: 110px;
  }

  .lg\:mb-\[130px\] {
    margin-bottom: 130px;
  }

  .lg\:mb-\[40px\] {
    margin-bottom: 40px;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-\[180px\] {
    width: 180px;
  }

  .lg\:w-\[255px\] {
    width: 255px;
  }

  .lg\:w-\[calc\(\(100\%-90px\)\/4\)\] {
    width: calc(25% - 22.5px);
  }

  .lg\:w-\[calc\(50\%-5px\)\] {
    width: calc(50% - 5px);
  }

  .lg\:w-fit {
    width: -moz-fit-content;
    width: fit-content;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-0 {
    gap: 0;
  }

  .lg\:gap-\[10px\] {
    gap: 10px;
  }

  .lg\:gap-\[15px\] {
    gap: 15px;
  }

  .lg\:gap-\[20px\] {
    gap: 20px;
  }

  .lg\:gap-\[2px\] {
    gap: 2px;
  }

  .lg\:gap-\[30px\] {
    gap: 30px;
  }

  .lg\:gap-\[50px\] {
    gap: 50px;
  }

  .lg\:rounded-\[10px\] {
    border-radius: 10px;
  }

  .lg\:border {
    border-width: 1px;
  }

  .lg\:border-\[\#0041F5\] {
    --tw-border-opacity: 1;
    border-color: rgb(0 65 245 / var(--tw-border-opacity));
  }

  .lg\:px-\[22px\] {
    padding-left: 22px;
    padding-right: 22px;
  }

  .lg\:py-\[17px\] {
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-\[12px\] {
    font-size: 12px;
  }

  .lg\:text-\[14px\] {
    font-size: 14px;
  }

  .lg\:text-\[16px\] {
    font-size: 16px;
  }

  .lg\:text-\[18px\] {
    font-size: 18px;
  }

  .lg\:text-\[20px\] {
    font-size: 20px;
  }

  .lg\:text-\[34px\] {
    font-size: 34px;
  }

  .lg\:text-\[42px\] {
    font-size: 42px;
  }

  .lg\:font-bold {
    font-weight: 700;
  }

  .lg\:leading-\[130\%\] {
    line-height: 130%;
  }

  .lg\:leading-\[140\%\] {
    line-height: 140%;
  }

  .lg\:leading-\[18px\] {
    line-height: 18px;
  }

  .lg\:leading-\[26px\] {
    line-height: 26px;
  }

  .lg\:leading-\[28px\] {
    line-height: 28px;
  }

  .lg\:leading-\[54px\] {
    line-height: 54px;
  }

  .lg\:no-underline {
    text-decoration-line: none;
  }

  .lg\:hover\:translate-y-\[-5\%\]:hover {
    --tw-translate-y: -5%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:hover\:bg-\[\#0041F5\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 65 245 / var(--tw-bg-opacity));
  }

  .lg\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .lg\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (min-width: 1200px) {
  .xl\:absolute {
    position: absolute;
  }

  .xl\:relative {
    position: relative;
  }

  .xl\:bottom-\[35px\] {
    bottom: 35px;
  }

  .xl\:bottom-\[65px\] {
    bottom: 65px;
  }

  .xl\:right-\[-20px\] {
    right: -20px;
  }

  .xl\:right-\[-55px\] {
    right: -55px;
  }

  .xl\:right-\[25px\] {
    right: 25px;
  }

  .xl\:mb-\[20px\] {
    margin-bottom: 20px;
  }

  .xl\:mb-\[40px\] {
    margin-bottom: 40px;
  }

  .xl\:mb-\[50px\] {
    margin-bottom: 50px;
  }

  .xl\:ml-\[55px\] {
    margin-left: 55px;
  }

  .xl\:mt-auto {
    margin-top: auto;
  }

  .xl\:block {
    display: block;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:aspect-\[57\/35\] {
    aspect-ratio: 57 / 35;
  }

  .xl\:h-\[260px\] {
    height: 260px;
  }

  .xl\:h-\[265px\] {
    height: 265px;
  }

  .xl\:h-\[480px\] {
    height: 480px;
  }

  .xl\:h-fit {
    height: -moz-fit-content;
    height: fit-content;
  }

  .xl\:h-full {
    height: 100%;
  }

  .xl\:w-\[210px\] {
    width: 210px;
  }

  .xl\:w-\[325px\] {
    width: 325px;
  }

  .xl\:w-\[445px\] {
    width: 445px;
  }

  .xl\:w-\[475px\] {
    width: 475px;
  }

  .xl\:w-\[540px\] {
    width: 540px;
  }

  .xl\:w-\[570px\] {
    width: 570px;
  }

  .xl\:w-\[635px\] {
    width: 635px;
  }

  .xl\:w-\[calc\(25\%-24px\)\] {
    width: calc(25% - 24px);
  }

  .xl\:w-\[calc\(33\.33\%-20px\)\] {
    width: calc(33.33% - 20px);
  }

  .xl\:w-\[calc\(50\%-33px\)\] {
    width: calc(50% - 33px);
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:max-w-\[180px\] {
    max-width: 180px;
  }

  .xl\:max-w-\[250px\] {
    max-width: 250px;
  }

  .xl\:max-w-\[380px\] {
    max-width: 380px;
  }

  .xl\:max-w-\[730px\] {
    max-width: 730px;
  }

  .xl\:max-w-\[unset\] {
    max-width: unset;
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-wrap {
    flex-wrap: wrap;
  }

  .xl\:items-start {
    align-items: flex-start;
  }

  .xl\:items-center {
    align-items: center;
  }

  .xl\:gap-\[10px\] {
    gap: 10px;
  }

  .xl\:gap-\[20px\] {
    gap: 20px;
  }

  .xl\:gap-\[30px\] {
    gap: 30px;
  }

  .xl\:gap-\[50px\] {
    gap: 50px;
  }

  .xl\:gap-\[75px\] {
    gap: 75px;
  }

  .xl\:gap-\[95px\] {
    gap: 95px;
  }

  .xl\:gap-x-\[65px\] {
    column-gap: 65px;
  }

  .xl\:gap-y-\[40px\] {
    row-gap: 40px;
  }

  .xl\:gap-y-\[50px\] {
    row-gap: 50px;
  }

  .xl\:gap-y-\[75px\] {
    row-gap: 75px;
  }

  .xl\:overflow-hidden {
    overflow: hidden;
  }

  .xl\:p-\[32px\] {
    padding: 32px;
  }

  .xl\:p-\[35px\] {
    padding: 35px;
  }

  .xl\:px-\[calc\(\(100\%-1110px\)\/2\)\] {
    padding-left: calc(50% - 555px);
    padding-right: calc(50% - 555px);
  }

  .xl\:py-\[70px\] {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .xl\:pt-\[100px\] {
    padding-top: 100px;
  }

  .xl\:pt-\[105px\] {
    padding-top: 105px;
  }

  .xl\:text-left {
    text-align: left;
  }

  .xl\:text-\[14px\] {
    font-size: 14px;
  }

  .xl\:text-\[18px\] {
    font-size: 18px;
  }

  .xl\:text-\[20px\] {
    font-size: 20px;
  }

  .xl\:text-\[26px\] {
    font-size: 26px;
  }

  .xl\:text-\[34px\] {
    font-size: 34px;
  }

  .xl\:leading-\[140\%\] {
    line-height: 140%;
  }

  .xl\:leading-\[150\%\] {
    line-height: 150%;
  }

  .xl\:leading-\[20px\] {
    line-height: 20px;
  }
}

/*# sourceMappingURL=index.5906267d.css.map */
